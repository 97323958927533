import NavFactory from "@/client/extensions/helpers/navFactory.js";

export default async ({ asyncOps, store }) => {
  // older code - we did not have navFactory then
  let adminNavItemFactoryFunction;
  let navItems;
  try {
    adminNavItemFactoryFunction =
      await require("@/client/assets/static/core/nav/adminSideNavFactory.js");
    navItems = await adminNavItemFactoryFunction.default({
      asyncOps,
      store,
    });
  } catch (e) {
    return {};
  }

  let nav = new NavFactory(navItems);

  let fileAccountItemTitle = {
    action: {
      type: "title",
    },
    label: "sf.admin.sideNav.fileAccountTitle",
  };
  let fileAccountList = {
    icon: "info",
    label: "sf.admin.sideNav.fileAccountList",
    action: {
      type: "routerNavigation",
      data: {
        to: {
          name: "admin-entity-entityType",
          params: {entityType: 'fileAccount'},
        },
      },
    },
  };

  nav
    .addItemsAfter("dashboard", {
      fileAccountItemTitle,
      fileAccountList,
    })

    .removeItems(["ecommerceTitle", "vendor", "brand", "product", "order"]);
  return nav.getItems();
};
